export function RightArrowIcon({
  arrowColor = '#000',
  bgColor = '#fff',
  borderRadius = '5',
  removeBorder = false,
  ...props
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <rect
        width="24"
        height="24"
        fill={bgColor}
        rx={borderRadius}
        transform="matrix(-1 0 0 1 24 0)"
      ></rect>
      <rect
        width="23.25"
        height="23.25"
        x="-0.375"
        y="0.375"
        stroke={arrowColor}
        strokeOpacity="0.12"
        strokeWidth={!removeBorder ? '0.75' : '0'}
        rx={borderRadius}
        transform="matrix(-1 0 0 1 23.25 0)"
      ></rect>
      <g clipPath="url(#clip0_304_3140)">
        <path
          stroke={arrowColor}
          strokeWidth="1.5"
          d="M10.39 7.885l3.88 3.88-3.88 3.878"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_304_3140">
          <path
            fill="#fff"
            d="M0 0H13.714V13.714H0z"
            transform="matrix(0 1 1 0 5.14 5.143)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export function DownArrowIcon({ arrowColor = '#fff', ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <g>
        <g>
          <mask
            id="mask0_666_6105"
            style={{ maskType: 'alpha' }}
            width="16"
            height="10"
            x="0"
            y="3"
            maskUnits="userSpaceOnUse"
          >
            <path fill="#D9D9D9" d="M0 3H16V13H0z"></path>
          </mask>
          <g mask="url(#mask0_666_6105)">
            <path
              stroke={arrowColor}
              strokeWidth="1.6"
              d="M2.343 5.657L8 11.314l5.657-5.657"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
